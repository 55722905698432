import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export const Loading = ({ text = "Please wait...", className, ...props }) => {
  return (
    <div className={classNames("loading-spinner", className)} {...props}>
      <FontAwesomeIcon
        className="loading-spinner__icon"
        icon={faSpinner}
        spin
      />{" "}
      <span className="loading-spinner__text">{text}</span>
    </div>
  );
};

export default Loading;
